<template>
  <div v-if="topBannerData">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="(image, index) in topBannerData"
        :key="index"
        :img-src="image.url.fileUrl"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Top-Banner",
  components: {},
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    ...mapState({
      topBannerData: ({ advertisement }) => advertisement.topBanner,
    }),
  },
  methods: {
    ...mapActions({
      fetchTopBannerData: "advertisement/topBannerData",
    }),

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },

  mounted() {
    this.fetchTopBannerData();
  },
};
</script>

<style scoped>
.text-black-color {
  color: black !important;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .downloadhere-mobile-view {
    display: none !important;
  }
}
</style>
<style>
.bg-secondary {
  background-color: white !important;
}
</style>
